import { graphql } from "gatsby";
import React, { useState } from "react";
import Layout from "~/templates/Layout";
import { LayoutRenderer } from '~/components/layouts/LayoutRenderer';
import useBlogPosts from '~/hooks/useBlogPosts';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import { ResourceCard } from "../components/landing/Resources";
import { UnderlineLink, LinkInner } from '~/components/elements/Link';
import { Newsletter } from "~/components/flexible/Newsletter";
import { MediaImage } from "~/components/elements/Image";
import AddToCartButton from "~/components/shop/cart/AddToCartButton";
import { Phone, Dollar, Rechargeable, Push, Bluetooth, Receiver, ThinCross } from '~/components/elements/Icon';
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Pagination } from "swiper";
import Image from '~/components/elements/Image';
import 'swiper/css';
import 'swiper/css/pagination';
import { StaticImage } from "gatsby-plugin-image";
import Countdown from 'react-countdown';

export default function productPage({
  data: { wpPage, wp, wpProduct },
}: {
  data: { wpProduct };
}) {

  const {showHeaderAddToCart, showFooterAddToCart} = wpProduct.postTypeProduct;

  console.log(wpProduct);

  return (
    <Layout wp={wp} headerColor="light-blue">
      <Seo post={wpProduct} title={wpProduct.name} />
      <Masthead {...wpProduct} HeadingTag="h1" showQuestionCta={true} showPromoBar={true} showAddToCart={showHeaderAddToCart} />
      <LayoutRenderer
        prefix="Product_Posttypeproduct_Sections_Section_Components_"
        sections={wpProduct?.postTypeProduct?.sections}
      />
      {showFooterAddToCart && <Masthead {...wpProduct} HeadingTag="h2" showPromoBar={false} showQuestionCta={false} showAddToCart={true} />}
    </Layout>
  );
}

export const Masthead = (props) => {

  const { HeadingTag, showAddToCart, showPromoBar, showQuestionCta, featuredImage, name, postTypeProduct, regularPrice, salePrice, galleryImages } = props

  const showPrice = postTypeProduct.showPrice;
  const showFromPrice = postTypeProduct.showFromPrice;

  return (
    <div>
      {showPromoBar && postTypeProduct?.specialOffer && <PromoBar {...postTypeProduct?.specialOfferContent} />}
      <section className="section !pt-7 section-bot bg-blue-light">
        <div className="container">
          <div className="flex flex-col space-y-10 lg:space-y-0 lg:flex-row lg:items-center lg:space-x-10">
            <div className="w-full lg:flex-1">
              <ProductSlider {...postTypeProduct} />
            </div>
            <div className="w-full lg:w-[45%] ">
              <div className="bg-white rounded-[10px] card-shadow p-5 py-10 lg:p-10 space-y-8">
                <div className="space-y-3">
                  {postTypeProduct?.productVendor && <div className="text-blue font-semibold">{postTypeProduct.productVendor}</div>}
                  <HeadingTag className="t-42">{name}</HeadingTag>
                  <div className="trustpilot-widget w-[189px] xl:w-[420px] xl:-ml-2.5" data-locale="en-US" data-template-id="5419b6ffb0d04a076446a9af" data-businessunit-id="5fc67a080737a300019b2dd4" data-style-height="20px" data-style-width="100%" data-theme="light" data-text-color="#0B3F6F">
                    <a href="https://www.trustpilot.com/review/yeshearing.com" target="_blank" rel="noopener"><span className="hidden">Trustpilot</span></a>
                  </div>
                  {postTypeProduct?.productDescription && (
                    <div className="t-18 t-black-65" dangerouslySetInnerHTML={{ __html: postTypeProduct?.productDescription }} />
                  )}

                  {postTypeProduct?.features && <Features {...postTypeProduct} />}
                  {postTypeProduct?.showPrice && (
                    <div className="t-24 text-blue">
                      {showFromPrice && <div className="pt-2 t-17 text-[#666]">From</div>}
                      {salePrice ? (
                        <div className="space-x-2 flex items-center">
                          <div>{salePrice}</div><s className="t-17">{regularPrice}</s>
                        </div>
                      ) : (regularPrice)}
                    </div>
                  )}
                </div>
                <div className="space-y-4">
                  <div className="w-full fw-button">
                    {showAddToCart ? (
                      <AddToCartButton product={props} />
                    ) : (
                      <LinkInner link={{ title: 'Speak to a specialist', url: '/book-a-consultation' }} type="button" />
                    )}
                  </div>


                  <div className="flex justify-center space-x-4">
                    <a href="tel:844-903-2070" className="flex items-center space-x-2">
                      <div className="text-green">
                        <Phone />
                      </div>
                      <div className="underline block text-navy font-medium">844-903-2070</div>
                    </a>
                    <a href="tel:844-903-2070" className="flex items-center space-x-2">
                      <div className="text-green">
                        <Dollar />
                      </div>
                      <div className="underline block text-navy font-medium">Spread the cost</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {showQuestionCta && (
        <div className="bg-navy text-white">
          <div className="container container-full">
            <div className="px-5 lg:px-10 py-10 lg:py-4 flex flex-col space-y-5 lg:space-y-0 lg:flex-row items-center">
              <div className="t-21">{name}</div>
              <div className="lg:ml-auto flex flex-col space-y-5 lg:space-y-0 lg:flex-row items-center lg:space-x-10">
                <div className="flex items-center space-x-2 font-medium">
                  <div className="text-green">
                    <Phone />
                  </div>
                  <div>Question? Call us on</div>
                  <a href="tel:844-903-2070" className="underline block font-semibold">844-903-2070</a>
                </div>
                <LinkInner link={{ url: '/book-a-consultation', title: 'Speak with a specialist' }} type="button-secondary" />
              </div>
            </div>
          </div>
        </div>
        )
      }
    </div>
  )
}


export const ProductSlider = (props) => {

  const { images } = props


  return (
    // <pre>{JSON.stringify(images, null, 4)}</pre>

    <Swiper
      modules={[A11y, Pagination]}
      className={`w-full z-10 relative !pb-12`}
      slidesPerView={'auto'}
      loop
      pagination={{ clickable: true }}
    >
      <div className="hidden pb-12" />
      {images?.map((image, i) => (
        <SwiperSlide key={`productSlide${i}`}>
          <div className="aspect-w-1 aspect-h-1 overflow-hidden">
            <Image image={image} className="!absolute" objectFit="cover" objectPosition="center" />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export const Features = ({ features }) => {
  return (
    <ul className="grid sm:grid-cols-2 gap-2.5">
      {[
        { value: 'rechargeable', label: 'Rechargeable Battery' },
        { value: 'push', label: 'Push Button' },
        { value: 'bluetooth', label: 'Bluetooth' },
        { value: 'receiver', label: 'Receiver in ear' }
      ].map((feature, i) => (
        <li className="flex items-center space-x-4">
          <div className="w-4 h-4 fwh-svg">
            {feature.value === 'rechargeable' ? (
              <Rechargeable />
            ) : feature.value === 'push' ? (
              <Push />
            ) : feature.value === 'bluetooth' ? (
              <Bluetooth />
            ) : feature.value === 'receiver' && (
              <Receiver />
            )}
          </div>
          <div className="font-medium">
            {feature.label}
          </div>
        </li>
      ))}
    </ul>
  )
}


export const PromoBar = (props) => {

  const [hidePromoBanner, setHidePromoBanner] = useState(false)

  const { heading, content, date } = props

  return (
    <div className={`bg-orange text-white py-4 ${hidePromoBanner && 'hidden'}`}>
      <div className="container">
        <div className="flex space-y-5 lg:space-y-0 flex-col lg:flex-row lg:space-x-8 text-center lg:text-left items-center lg:justify-center relative">
          
          <div className="w-10 md:w-16">
            <StaticImage src="../assets/images/offer.png" alt="" className="w-full object-contain" />
          </div>
          <div className="space-y-1">
            {/* todo: Connect to the CMS */}
            {/* {heading && <div className="font-semibold">{heading}</div>}
            {content && <div>{content}</div>} */}
            <div className="font-semibold">Limited Time Offer</div>
            <div>Dispatch from 00/00/00 for Audeo Paradise 90</div>
          </div>
          <div className="leading-none md:leading-6">
            <div className="t-36 !font-body font-semibold"><Countdown date={Date.now() + (5 * 24 * 60 * 60 * 1000)} /></div>
          </div>
          <button onClick={() => setHidePromoBanner(true)} className="absolute top-4 right-0 w-7 h-7 bg-black bg-opacity-30 text-white rounded-full overflow-hidden flex items-center justify-center hover:bg-opacity-100 transition-colors duration-200 ease-in-out">
            <div className="w-2.5 fw-svg">
              <ThinCross />
            </div>
          </button>
        </div>
      </div>
    </div>
  )
}



export const pageQuery = graphql`
  query Product($id: String!) {
    wpPage(id: { eq: $id }) {
      ...SEO
      ...GeneratedWpPage
    }
    wp {
      ...GeneratedWp
    }
    wpProduct(id: { eq: $id }) {
      ...GeneratedWpProduct
      ... on WpSimpleProduct {
        id
        name
        regularPrice
        salePrice
      }
    }
  }
`;
